import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import { Accordion, AccordionItem } from '@jsluna/react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const ExampleBox = makeShortcode("ExampleBox");
const AccordionExample = makeShortcode("AccordionExample");
const AccordionWithFooterExample = makeShortcode("AccordionWithFooterExample");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#how-to-use-an-accordion"
            }}>{`How to use an accordion`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#types-of-accordion"
            }}>{`Types of accordion`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#content-guidelines"
            }}>{`Content guidelines`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "how-to-use-an-accordion",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-use-an-accordion",
        "aria-label": "how to use an accordion permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to use an accordion`}</h2>
    <p>{`When using accordions:`}</p>
    <ul>
      <li parentName="ul">{`Group accordions with related information together to help users scan the content, for example in frequently asked questions sections`}</li>
      <li parentName="ul">{`Hide additional information that isn’t needed at that moment for a user to complete their task. For example, hide the billing address when the user is completing the delivery address`}</li>
      <li parentName="ul">{`Make sure hidden content in a collapsed accordion cannot be accessed by screen reader and keyboard users
is this here`}</li>
    </ul>
    <ExampleBox mdxType="ExampleBox">
  <AccordionExample mdxType="AccordionExample" />
    </ExampleBox>
    <p>{`Don’t use an accordion if there isn’t enough content to condense.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "types-of-accordion",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#types-of-accordion",
        "aria-label": "types of accordion permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Types of accordion`}</h2>
    <h3>{`Grouped accordion`}</h3>
    <p>{`A grouped accordion helps users quickly understand related information.`}</p>
    <p>{`You can set the accordion to be open by default if you want users to see the content, but also give the flexibility to hide it.`}</p>
    <p>{`Content in collapsed accordions is visually hidden and cannot be tabbed to or read out by keyboard and screen reader users.`}</p>
    <p>{`You can link the state of grouped accordions so that when the user opens one, the accordion that’s currently open is collapsed.`}</p>
    <p>{`Don’t restrict the number of open sections if users need to compare the content.`}</p>
    <h3>{`Accordion with footer`}</h3>
    <ExampleBox mdxType="ExampleBox">
  <AccordionWithFooterExample mdxType="AccordionWithFooterExample"></AccordionWithFooterExample>
    </ExampleBox>
    <hr></hr>
    <h2 {...{
      "id": "content-guidelines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#content-guidelines",
        "aria-label": "content guidelines permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Content guidelines`}</h2>
    <p>{`Make accordion titles concise and descriptive, and make sure it’s clear what content to expect when opened.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <Accordion mdxType="Accordion">
      <AccordionItem title="Full product details" mdxType="AccordionItem">
        <ul>
          <li>Model number: GA00781-GB</li>
          <li>Size H4.2, W9.8, D9.8cm</li>
          <li>Speaker diameter 9.8cm</li>
          <li>Mains operated</li>
          <li>Manufacturer's 1 year guarantee</li>
          <li>EAN: 193575000886</li>
        </ul>
      </AccordionItem>
    </Accordion>
  </Do>
  <Dont mdxType="Dont">
    <Accordion mdxType="Accordion">
      <AccordionItem title="More information" mdxType="AccordionItem">
        <ul>
          <li>Model number: GA00781-GB</li>
          <li>Size H4.2, W9.8, D9.8cm</li>
          <li>Speaker diameter 9.8cm</li>
          <li>Mains operated</li>
          <li>Manufacturer's 1 year guarantee</li>
          <li>EAN: 193575000886</li>
        </ul>
      </AccordionItem>
    </Accordion>
  </Dont>
    </Guideline>
    <p>{`Start titles with a capital letter. You can end titles with a question mark (for example if you’re creating an FAQ), but don’t end them with commas, semicolons or full stops.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <Accordion titleElement="h3" mdxType="Accordion">
      <AccordionItem title="What if there's a problem with my delivery?" mdxType="AccordionItem">
        <p>
          If you have any problems with your delivery, you can contact us via
          our help page.
        </p>
      </AccordionItem>
    </Accordion>
  </Do>
  <Dont mdxType="Dont">
    <Accordion titleElement="h3" mdxType="Accordion">
      <AccordionItem title="What to do if there's a problem with your delivery..." mdxType="AccordionItem">
        <p>
          If you have any problems with your delivery, you can contact us via
          our help page.
        </p>
      </AccordionItem>
    </Accordion>
  </Dont>
    </Guideline>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our team can answer any questions about using accordions or give you a helping
  hand with your next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      